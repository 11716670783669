import menuCommunity from '~/configs/menus/community.menu'
import menuContent from '~/configs/menus/content.menu'
import menuCourse from '~/configs/menus/course.menu'
// import menuEcommerce from '~/configs/menus/ecommerce.menu'
import menuPage from '~/configs/menus/page.menu'
import menuPromotion from '~/configs/menus/promotion.menu'
import menuWhatsapp from '~/configs/menus/whatsapp.menu'
import {routeDict} from '~/router'

let menu: any = [
    // {
    //     text: 'Ecommerce',
    //     items: menuEcommerce
    // },
    {
        text: 'Course',
        items: menuCourse
    },
    {
        text: 'Content',
        items: menuContent
    },
    {
        text: 'Promotion',
        items: menuPromotion
    },
    {
        text: 'Community',
        items: menuCommunity
    },
    {
        text: 'Whatsapp',
        items: menuWhatsapp
    },
    {
        text: 'GG',
        items: menuPage
    },
]
const navigation = {
    // main navigation - side menu
    menu: menu
}

export default navigation
